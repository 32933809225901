import { Grid } from '@mui/material';
import React, { memo } from 'react';

import Button from '../../Buttons/Button';
import styles from './styles.module.css';
const state = {
  estrutura: true,
  integridade: false,
  segurança: false,
  validação: false,
  parceria: false,
  lastOpen: 'estrutura',
};
const Diferential: React.FunctionComponent = () => {
  const [open, setOpen] = React.useState(state);

  const openToggle = event => {
    const id = event.target.id;
    setOpen({
      estrutura: id === 'estrutura',
      integridade: id === 'integridade',
      segurança: id === 'segurança',
      validação: id === 'validação',
      parceria: id === 'parceria',
      lastOpen: id,
    });
  };

  const toggles = {
    Estrutura: 'Temos estrutura tecnológica escalável',
    Integridade:
      'Garantimos integridade do uso de dados, 100% alinhados à LGPD',
    Segurança:
      'Propomos arquitetura tech, com algoritmo de segurança e integridade',
    Validação: 'Construímos validação econômica para as soluções',
    Parceria: 'Construímos parceria estratégica de negócios',
  };

  return (
    <>
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={12}
        spacing={3}
      >
        <Grid item className="barlow--size32 bold">
          Por que com a Devyx é diferente?
        </Grid>
        <Grid item className="open-sans--size18">
          Nós estamos atentos aos resultados dos clientes. Queremos
          <b>
            <span style={{ color: 'var(--primary-green)' }}>&nbsp;#</span>
            shiftyourevolution.
          </b>
        </Grid>
        <Grid item xs={12}>
          <Button href={'/who-we-are'}>Saiba mais</Button>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        xs={12}
        gap={'16px'}
        marginBottom="48px"
        flexWrap={{ xs: 'nowrap', lg: 'wrap' }}
      >
        {Object.keys(toggles).map(toggle => (
          <Grid
            item
            container
            direction="column"
            alignItems={'flex-start'}
            justifyContent={'flex-end'}
            id={toggle.toLowerCase()}
            onClick={openToggle}
            className={open[toggle.toLowerCase()] ? styles.open : styles.close}
            borderRadius={'16px'}
            width={
              open[toggle.toLowerCase()] ? { xs: '276px', lg: '445px' } : '13%'
            }
            key={toggle}
            style={{
              background: `url("/img/${toggle.toLowerCase()}.webp") no-repeat`,
              backgroundPosition: 'center center',
            }}
            padding={open[toggle.toLowerCase()] ? '24px' : '24px 0px'}
            height={'362px'}
          >
            <Grid
              component={'span'}
              item
              id={toggle.toLowerCase()}
              className="barlow--size40 bold white"
            >
              {toggle}
            </Grid>
            {open[toggle.toLowerCase()] && (
              <Grid
                component={'span'}
                item
                id={toggle.toLowerCase()}
                className="open-sans--size18 white"
              >
                {toggles[toggle]}
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default memo(Diferential);
